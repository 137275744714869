import React from "react";
// Pages
const Dashboard = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const UniversityMasterList = React.lazy(() => import('../pages/UniversityMaster/UniversityMasterList/UniversityMasterList'))
const UniversityMasterView = React.lazy(() => import('../pages/UniversityMaster/UniversityMasterView/UniversityMasterView'))
const UniversityMasterCreate = React.lazy(() => import('../pages/UniversityMaster/UniversityMasterCreate/UniversityMasterCreate'))
const CoursesList = React.lazy(() => import('../pages/Courses/Courseslist/Courseslist'))
const FormMaster = React.lazy(() => import('../pages/FromMaster/FromMaster'))
const Addcourse = React.lazy(() => import('../pages/Courses/Coursescreate/CoursesCreate'))
const EditCourse = React.lazy(()=>import('../pages/Courses/Coursesedit/Coursesedit'))
const AdminRoutes = [
    { path: "/dashboard", name: "Dashboard", element: Dashboard, exact: true },
    { path: "/UniversityMasterList", name: "UniversityMasterList", element: UniversityMasterList, exact: true },
    { path: "/UniversityMasterList/UniversityMasterView", name: "UniversityMasterView", element: UniversityMasterView, exact: true },
    { path: "/UniversityMasterList/UniversityMasterCreate", name: "UniversityMasterCreate", element: UniversityMasterCreate, exact: true },
    { path: "/CourseslistMaster", name: "Courses List", element: CoursesList, exact: true },
    { path: "/CourseslistMaster/Create", name: "Courses List", element: Addcourse, exact: true },
    { path: "/CourseslistMaster/edit", name: "Courses List", element: EditCourse, exact: true },
    { path: "/formMaster", name: "Courses List", element: FormMaster, exact: true },
];

export default AdminRoutes;

